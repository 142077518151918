<template>
  <v-card
    flat
    class="d-flex nmc-card align-center"
    @click="$emit('click')"
  >
    <v-avatar
      v-if="imgUrl"
      size="50"
      class="rounded ma-1 ml-0"
    >
      <v-img
        :src="imgUrl"
        :lazy-src="imgUrl"
        cover
      />
    </v-avatar>
    <v-sheet
      class="d-flex flex-column justify-center pe-2 ps-1 py-1"
      :class="imgUrl ? '': 'ml-13'"
      width="100%"
    >
      <v-card-title class="desig text-body-2 text-sm-subtitle-1 pa-0 flex-nowrap align-start">
        {{ nmc.libelle }}
      </v-card-title>

      <v-card-subtitle
        class="pa-0 ma-0 desc text-caption d-flex align-center"
      >
        {{ $t('quantite') }}: {{ nmc.qte }} <v-icon small>
          mdi-circle-small
        </v-icon>
        {{ $t('ref') }}: {{ nmc.ref }}
      </v-card-subtitle>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'NomenclatureCard',
  props: {
    nmc: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imgUrl () {
      return this.nmc.desc ? this.nmc.desc.images[0] : ''
    }
  }
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
.nmc-card{
  .desig{
    word-break: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    @media #{map-get($display-breakpoints, 'sm-and-up')}{
      -webkit-line-clamp: 1;
    }
    @media #{map-get($display-breakpoints, 'xs-only')}{
      -webkit-line-clamp: 2;
    }
    display: -webkit-box;
  }
  .desc{
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-height: 1.25;
    @media #{map-get($display-breakpoints, 'sm-and-up')}{
      -webkit-line-clamp: 2;
    }
    @media #{map-get($display-breakpoints, 'xs-only')}{
      -webkit-line-clamp: 2;
    }
    display: -webkit-box;
  }
}
</style>
